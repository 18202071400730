import { ADMIN_API_URL } from "../../config"
import { IClientDetails, IClientsList } from "../../interfaces"
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit"
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"

// Filters type
type Filters = {
  office: string
}

// Define a type for the slice state
interface IClients {
  list: IClientsList
  filteredList: IClientsList
  filters: Filters
  loading: boolean
  error: Error | null
}

// Define the initial state using that type
const initialState: IClients = {
  list: [],
  filteredList: [],
  filters: {
    office: "",
  },
  loading: false,
  error: null,
}

export const slice = createSlice({
  name: "clients",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    getDataLoading(state) {
      state.loading = true
    },
    getClientsListSuccess(state, action: PayloadAction<IClientsList>) {
      state.loading = false
      state.error = null
      state.list = action.payload
    },
    getClientsListError(state, action: PayloadAction<Error>) {
      state.loading = false
      state.list = []
      state.error = action.payload
    },
    getClientSuccess(state, action: PayloadAction<IClientDetails>) {
      state.loading = false
      state.error = null
      state.list = [action.payload]
    },
    getClientError(state, action: PayloadAction<Error>) {
      state.loading = false
      state.list = []
      state.error = action.payload
    },
    updateIsEmailSent(
      state,
      action: PayloadAction<{ id: string; isEmailSent: boolean }>
    ) {
      state.list = state.list.map((n) => {
        if (n.id === action.payload.id) {
          n.isEmailSent = action.payload.isEmailSent
        }
        return n
      })
    },
    updateOfficeFilter(state, action: PayloadAction<{ office: string }>) {
      state.filters.office = action.payload.office
    },
    getClientsByBatchFmnosSuccess(
      state,
      action: PayloadAction<IClientsList>
    ) {
      state.loading = false
      state.error = null
      state.list = action.payload
    },
  },
})

const { reducer, actions } = slice

const {
  getDataLoading,
  getClientsListSuccess,
  getClientsListError,
  getClientSuccess,
  getClientError,
  updateIsEmailSent,
  updateOfficeFilter,
  getClientsByBatchFmnosSuccess,
} = actions

export const setOfficeCode = (office: string) => async (dispatch: Dispatch) => {
  dispatch(updateOfficeFilter({ office }))
}


/**
 * batch search by fmnos list
 * @param recipient_workmail list of fmnos
 */
export const batchSearchFmnos =
  (accessToken: string | undefined, recipient_workmail: string[]) =>
  async (dispatch: any) => {
    // if missing access token show error
    if (!accessToken) {
      dispatch(getClientsListError(new Error("missing required access token")))
    }

    const axiosConfig: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
    const url: string = `${ADMIN_API_URL}/clients`

    // remove other filters
    dispatch(updateOfficeFilter({ office: "" }))

    try {
      dispatch(getDataLoading())
      const resp: AxiosResponse = await axios.get<IClientsList>(
        url,
        axiosConfig
      )
      const clientsList = resp.data

      // list to track found fmnos
      let foundRecipient_workmail: IClientDetails[] = []

      // list to track not found fmnos
      let notFoundRecipient_workmail: string[] = []


      // display error if at least 1 not found fmno
      if (notFoundRecipient_workmail.length > 0) {
        dispatch(
          getClientsListError(new Error(`Sorry, we can't find ${notFoundRecipient_workmail}`))
        )
      }
      dispatch(getClientsByBatchFmnosSuccess(foundRecipient_workmail))
    } catch (err: any) {
      const errMsg = processAxiosError(err)
      dispatch(getClientsListError(errMsg))
    }
  }

export const fetchListOfClients =
  (accessToken: string | undefined) => async (dispatch: any) => {
    // if missing access token show error
    if (!accessToken) {
      dispatch(getClientsListError(new Error("missing required access token")))
    }

    const axiosConfig: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
    const url: string = `${ADMIN_API_URL}/clients`
    // remove other filters
    dispatch(updateOfficeFilter({ office: "" }))

    try {
      dispatch(getDataLoading())
      const resp: AxiosResponse = await axios.get<IClientsList>(
        url,
        axiosConfig
      )
      dispatch(getClientsListSuccess(resp.data))
    } catch (err: any) {
      const errMsg = processAxiosError(err)
      dispatch(getClientsListError(errMsg))
    }
  }

/**
 * fetch a client by email
 * @param accessToken okta JWT token
 * @param email user's email
 * @returns
 */
export const fetchClientByEmail =
  (accessToken: string | undefined, email: string) =>
  async (dispatch: Dispatch) => {
    dispatch(getDataLoading())

    // if missing access token show error
    if (!accessToken) {
      dispatch(getClientError(new Error("missing required access token")))
    }

    const axiosConfig: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
    const url: string = `${ADMIN_API_URL}/client?email=${email}`

    // remove other filters
    dispatch(updateOfficeFilter({ office: "" }))

    try {
      const resp: AxiosResponse = await axios.get<IClientDetails>(
        url,
        axiosConfig
      )
      dispatch(getClientSuccess(resp.data))
    } catch (err: any) {
      const errMsg = processAxiosError(err)
      dispatch(getClientError(errMsg))
    }
  }

/**
 * fetch a client by fmno
 * @param accessToken okta JWT token
 * @param fmno user's fmno
 * @returns
 */
export const fetchClientByFmno =
  (accessToken: string | undefined, fmno: number) =>
  async (dispatch: Dispatch) => {
    dispatch(getDataLoading())

    // if missing access token show error
    if (!accessToken) {
      dispatch(getClientError(new Error("missing required access token")))
    }

    const axiosConfig: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
    const url: string = `${ADMIN_API_URL}/client?fmno=${fmno}`

    // remove other filters
    dispatch(updateOfficeFilter({ office: "" }))

    try {
      const resp: AxiosResponse = await axios.get<IClientDetails>(
        url,
        axiosConfig
      )
      dispatch(getClientSuccess(resp.data))
    } catch (err: any) {
      const errMsg = processAxiosError(err)
      dispatch(getClientError(errMsg))
    }
  }

/**
 * update new-hire's progress status
 * @param id new-hire's unique id
 * @param isEmailSent new-hire's isEmailSent progress status
 * @returns
 */
export const updateClientProgress =
  (id: string, isEmailSent: boolean) => async (dispatch: any) => {
    dispatch(updateIsEmailSent({ id, isEmailSent }))
  }

const processAxiosError = (err: Error | AxiosError): Error => {
  if (!axios.isAxiosError(err)) {
    return err
  }

  const errData = err.response?.data
  if (!errData) {
    return err
  }

  if (errData["errors"] && errData["errors"].length > 0) {
    return new Error(errData["errors"][0])
  }

  if (errData["error"]) {
    return new Error(errData["error"])
  }

  return new Error("Unexpected error")
}

export default reducer
