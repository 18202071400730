export const STATUS_OK: string = "#40916c"
export const STATUS_BAD: string = "#e63946"
export const McK_ELECTRIC_BLUE: string = "#2251FF"
export const McK_DEEP_BLUE: string = "#051C2C"
export const McK_MID_GRAY: string = "#757575"
export const McK_LIGHT_GRAY: string = "#B3B3B3"
export const McK_SUPER_LIGHT_GRAY = "#D0D0D0"
export const McK_PALE_GRAY: string = "#E6E6E6"
export const McK_SUPER_PALE_GRAY: string = "#F0F0F0"
export const McK_WHITE: string = "#FFFFFF"
export const McK_DARK_GRAY: string = "#333333"
export const McK_RED: string = '#E5546C'
