import { appInsights } from "../config"
import { FunctionComponent } from "react"
import { Container, makeStyles } from "@material-ui/core"
import NotFoundImg from "../assets/images/not-found.png"
import { McKSansMedium, McKSansRegular } from "../styles/fonts"
import { McK_DARK_GRAY, McK_ELECTRIC_BLUE } from "../styles/colors"

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxWidth: '700px',
        margin: '50px auto',
        fontFamily: McKSansMedium,
        textAlign: 'center',
        color: McK_DARK_GRAY,
    },
    img: {
        width: '100%',
        maxWidth: '500px'
    },
    headerTxt: {
        color: McK_DARK_GRAY,
        fontSize: '2rem',
        fontWeight: 500,
    },
    paragraphTxt: {
        fontSize: '16px',
        fontFamily: McKSansRegular,
    },
    paragraphContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    homeLink: {
        color: 'inherit',
        "&:hover": {
            color: McK_ELECTRIC_BLUE,
        }
    },
}))

const NotFound: FunctionComponent = () => {
    appInsights.trackPageView({ name: 'NotFound' })
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Container>
                <img alt="page not found" className={classes.img} src={NotFoundImg} />
            </Container>
            <Container>
                <h4 className={classes.headerTxt}>The page you are looking for does not exist.</h4>
            </Container>
            <Container className={classes.paragraphContainer}>
                <p className={classes.paragraphTxt}>We apologize for the error. Please go back or return <a className={classes.homeLink} href="/">home</a>.</p>
            </Container>
        </div>
    )
}

export default NotFound