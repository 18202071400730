import { FunctionComponent } from "react"
import { Button, Container, makeStyles } from "@material-ui/core"
import { McKSansMedium, McKSansRegular } from "../styles/fonts"
import { McK_DARK_GRAY, McK_ELECTRIC_BLUE } from "../styles/colors"
import UnauthorizedImg from "../assets/illiustrations/unauthorized.svg"

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxWidth: '700px',
        margin: '50px auto',
        fontFamily: McKSansMedium,
        textAlign: 'center',
        color: McK_DARK_GRAY,
    },
    img: {
        width: '100%',
        maxWidth: '500px'
    },
    headerTxt: {
        color: McK_DARK_GRAY,
        fontSize: '2rem',
        fontWeight: 500,
    },
    paragraphTxt: {
        fontSize: '16px',
        fontFamily: McKSansRegular,
    },
    paragraphContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    homeLink: {
        color: 'inherit',
        "&:hover": {
            color: McK_ELECTRIC_BLUE,
        }
    },
}))

const OktaError: FunctionComponent<{ error: Error }> = ({ error }) => {
    const classes = useStyles()

    const errMsg = (error.name && error.message) ?
        `${error.name}: ${error.message}` : error.toString()

    return (
        <div className={classes.root}>
            <Container>
                <img alt="unauthorized error" className={classes.img} src={UnauthorizedImg} />
            </Container>
            <Container>
                <h4 className={classes.headerTxt}>{errMsg}</h4>
            </Container>
            <Container className={classes.paragraphContainer}>
                <p className={classes.paragraphTxt}>To access the app, please make sure you are added to the group of admins.</p>
            </Container>
            <Container>
                <Button
                    href="/"
                    color="primary"
                    variant="outlined"
                    style={{ color: McK_ELECTRIC_BLUE }}
                >
                    Go home
                </Button>
            </Container>
        </div>
    )
}

export default OktaError