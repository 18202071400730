import { styled } from "@material-ui/core/styles"
import { Box } from "@material-ui/core"
import { STATUS_BAD, STATUS_OK } from "../styles/colors"

interface AccountStatusProps {
    isActive: boolean
}

const AccountStatus = styled(Box)({
    display: 'flex',
    width: '30px',
    height: '10px',
    padding: '5px',
    border: (props: AccountStatusProps) =>
        props.isActive ?
            `1px solid ${STATUS_OK}`
            :
            `1px solid ${STATUS_BAD}`,
    borderRadius: '5px'
})

export default AccountStatus