import {
    TableRow,
    TableCell,
    IconButton,
    Collapse,
    Box,
    Typography,
    Tooltip,
    Checkbox,
    CircularProgress,
} from "@material-ui/core";
import {
    KeyboardArrowUp,
    KeyboardArrowDown,
    CheckCircleOutline,
    HighlightOffOutlined,
    ErrorOutline
} from "@material-ui/icons"
import { ROW_HEIGHT } from "./ClientsTable";
import { useAppSelector } from "../../redux/hooks";
import EmailActionButton from "../EmailActionButton"
import { useState, Fragment, useEffect, ChangeEvent } from "react"
import { IClientDetails, IClientShortDetails } from "../../interfaces"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

// interface of props of the component
interface IProps {
    row: IClientDetails
    id: string
    rowIndex: number
    isItemSelected: boolean
    labelId: string
    handleSelectClick: (event: ChangeEvent<unknown>, client: IClientShortDetails) => void
}

// interface of state of the component
interface State {
    allAccountStatus: boolean
    errorMessage: string
    isError: boolean
    isLoading: boolean
    openCollapse: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: `${ROW_HEIGHT}px`,
        },
        detailsCell: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            height: '30px',
        },
        accountLogo: {
            width: '100%',
            height: '100%'
        }
    }),
);

const getBackgroundColor = (index: number): string => {
    if (index % 2 === 0) {
        return "#EAF6FC"
    }
    return ""
}



const Row = (props: IProps) => {
    const classes = useStyles();
    const [values, setValues] = useState<State>({
        allAccountStatus: false,
        isError: false,
        errorMessage: '',
        isLoading: false,
        openCollapse: false
    })
    const { row, rowIndex, isItemSelected, labelId, handleSelectClick } = props;

    return (
        <Fragment>
            <TableRow
                className={classes.root}
                style={{ backgroundColor: getBackgroundColor(rowIndex) }}
                aria-checked={isItemSelected}
                selected={isItemSelected}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={isItemSelected}
                        onChange={(event: ChangeEvent) => {
                            handleSelectClick(event, { id: row.id, recipient_workmail: row.recipient_workmail, internal_cfs_upn: row.internal_cfs_upn } as IClientShortDetails)
                        }}
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setValues({ ...values, openCollapse: !values.openCollapse })}>
                        {values.openCollapse ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell align="left">{row.recipient_workmail}</TableCell>
                <TableCell align="left">{row.firstName}</TableCell>
                <TableCell align="left">{row.lastName}</TableCell>
                <TableCell align="left">{row.internal_cfs_upn}</TableCell>
                <TableCell align="center">
                    {/* <ClientsProgress client={row} /> */}
                    <EmailActionButton client={row} />
                </TableCell>
            </TableRow>
        </Fragment>
    )
}



export default Row