import { useNavigate } from 'react-router-dom';
import MckinseyLogo from "../assets/logos/mckinsey-logo-image.png"
import {
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
} from "@material-ui/core"
import { McK_DEEP_BLUE, McK_RED } from '../styles/colors';


// use red background color for non-prod build environment
const headerBackgroundColor = process.env.REACT_APP_BUILD_ENV === 'production' ? McK_DEEP_BLUE : McK_RED

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 20%',
        backgroundColor: headerBackgroundColor,
    },
    toolBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    logoButton: {
        width: '10rem',
    },
    title: {
        flexGrow: 1,
    },
}));

const Navbar = () => {
    const classes = useStyles();
    const navigate = useNavigate()

    return (
        <AppBar className={classes.root} position="static">
            <Toolbar className={classes.toolBar}>
                <IconButton
                    edge="start"
                    className={classes.logoButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={() => { navigate('/') }}
                >
                    <img alt="mckinsey logo" src={MckinseyLogo} style={{ width: '100%', height: ' 100%' }} />
                </IconButton>
            </Toolbar>
            {
                // display build environment value if non-prod build environment
                process.env.REACT_APP_BUILD_ENV !== 'production' &&
                <span style={{ fontSize: '10px', paddingLeft: '24px' }}>{process.env.REACT_APP_BUILD_ENV}</span>
            }
        </AppBar>
    )
}

export default Navbar