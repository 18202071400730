import { Home, Admin, NotFound } from "./"
import {
    Routes,
    Route
} from "react-router-dom"
import { LoginCallback } from '@okta/okta-react'
import OktaError from "./OktaError"
import { RequiredAuth } from "./PrivateRoute"



const SecureRoutes = () => {

    
    return (

        <Routes>
            <Route path='/' element={<Home />} ></Route>
                <Route path='/admin' element={< RequiredAuth />}>
                <Route path='' element={<Admin />} />
            </Route>

            <Route path='/login/callback' element={<LoginCallback errorComponent={OktaError} />} />
                <Route element={<NotFound />} />

        </Routes>

    )
}

export default SecureRoutes