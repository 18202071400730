import admin from "./admin"
import clients from "./clients"
import { combineReducers } from "@reduxjs/toolkit"

const rootReducer = combineReducers({
  admin,
  clients,
})

export default rootReducer
