import { ICustomProperties, SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../config"


// log user actions
export function trackEvent(eventName: string, properties?: ICustomProperties | undefined): void {
    appInsights.trackEvent({
        name: eventName,
        properties,
    })
}

// log diagnostic scenario
export function trackStackTrace(message: string, logProperties?: ICustomProperties | undefined) {
    appInsights.trackTrace({
        message,
        severityLevel: SeverityLevel.Information
    }, logProperties)
}

// log caught exceptions
export function trackException(error: Error | undefined): void {
    appInsights.trackException({
        error,
        severityLevel: SeverityLevel.Error,
    })
}