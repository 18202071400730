import { FunctionComponent } from "react"
import { Container, Button, makeStyles } from "@material-ui/core"
import { useNavigate } from 'react-router-dom';
import AdminImg from "../assets/illiustrations/admin.svg"
import { appInsights } from "../config";
import { McK_ELECTRIC_BLUE } from "../styles/colors";
import { McKSansLight } from "../styles/fonts";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'center',
        padding: '20px 20px',
        width: '100%',
        fontFamily: McKSansLight,
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            padding: 0,
            flexDirection: 'column',
        },
    },
    imgContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '50%',
        height: '300px',
        [theme.breakpoints.down('xs')]: {
            order: -1,
            width: '100%',
        },
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
    },
    srcImg: {
        width: '100%',
        height: '100%',
    },
    text: {
        fontSize: '20px',
        textAlign: 'center',
    },
    adminButton: {
        marginTop: '20px',
        textTransform: 'initial',
        backgroundColor: McK_ELECTRIC_BLUE,
    }
}))


const Home: FunctionComponent = () => {
    const classes = useStyles()
    const navigate = useNavigate()
    appInsights.trackPageView({ name: 'HomePage' })

    const handleClick = () => {
        navigate("/admin")
    }

    return (
        <Container className={classes.root}>
            <div className={classes.imgContainer}>
                <img className={classes.srcImg} alt="welcome admin" src={AdminImg}></img>
            </div>
            <div className={classes.contentContainer}>
                <p className={classes.text}>Here you can manage and monitor new hire's onboarding process</p>
                <Button
                    className={classes.adminButton}
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                >
                    Admin dashboard
                </Button>
            </div>
        </Container>
    )
}

export default Home