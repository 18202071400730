import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { McK_SUPER_PALE_GRAY } from "../../styles/colors";
import EmailAndFmnoSearch from "./EmailSearchBar";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            borderBottom: `1px solid ${McK_SUPER_PALE_GRAY}`,
            height: '100%',
            width: '100%',
            padding: '15px',
        },
        searchBar: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '70%',
            width: '100%',
        },
        toolTip: {
            display: 'flex',
            fontStyle: 'italic',
            height: '30%',
            fontSize: '14px',
        }
    }),
);

const SearchBar = () => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.searchBar}>
                <EmailAndFmnoSearch />
            </div>
            <div className={classes.toolTip}>
                <p>
                    To search for multiple FMNOs, separate them by comma. Example: 12345, 54321…
                </p>
            </div>
        </div>
    )
}

export default SearchBar