import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    CircularProgress,
    createStyles,
    Theme,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { IClientDetails, IClientShortDetails } from "../../interfaces"
import { Row, ClientsTableHead, SelectedClientsToolbar, ActionAlert } from ".."
import { useAppSelector } from "../../redux/hooks"
import { useState, MouseEvent, ChangeEvent, useEffect } from 'react'
import { getComparator, Order, stableSort } from "../../utils/sort"
import { McK_DEEP_BLUE } from "../../styles/colors"
import SearchBar from "../search-bar/SearchBar"

export const ROW_HEIGHT: number = 70
const ROWS_OPTIONS: number[] = [5, 10, 15, 25]

type AlertState = {
    open: boolean
    message: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '90%',
            '& td': {
                borderBottom: 'none',
            }
        },
        tableContainer: {
            width: '100%',
        },
        table: {
            minWidth: 750,
            '& td, th': {
                fontFamily: 'Lato, sans-serif'
            }
        },
        cell: {
            fontWeight: 'bold'
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        paginationActions: {
            color: 'white',
            '& .MuiTablePagination-selectIcon, .MuiTablePagination-actions': {
                color: 'white'
            },
            '& .MuiTablePagination-actions > button:disabled': {
                color: 'gray'
            }
        }
    }),
);

const ClientsTable = () => {
    const classes = useStyles();
    const { clients } = useAppSelector(state => state)
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof IClientDetails>('recipient_workmail');
    const [selected, setSelected] = useState<IClientShortDetails[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_OPTIONS[1]);
    const [alert, setAlert] = useState<AlertState>({ open: false, message: '' })

    useEffect(() => {
        if (clients.error) {
            setAlert({
                open: true,
                message: clients.error.message
            })
        }
    }, [clients.error])

    // use filtered list if office filter is used
    let clientsList = clients.filters.office ? clients.filteredList : clients.list

    function handleAlertClose(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({ open: false, message: '' })
    }

    /**
     * handles request to sort table by order
     * @param event mouse event
     * @param property object property to sort by
     */
    const handleRequestSort = (event: MouseEvent<unknown>, property: keyof IClientDetails) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    /**
     * handles selection of all new-hires in the table
     * @param event on input element change
     */
    const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const selectedList = clientsList.map((n: IClientDetails) => (
                { id: n.id, recipient_workmail: n.recipient_workmail, internal_cfs_upn: n.internal_cfs_upn } as IClientShortDetails)
            );
            setSelected(selectedList);
        } else {
            setSelected([]);
        }
    };

    /**
     * handles selection of a client in the table
     * @param event change event
     * @param recipient_workmail client's email
     */
    const handleSelectClick = (event: ChangeEvent<unknown>, client: IClientShortDetails) => {
        const selectedIndex = selected.findIndex((n) => n.recipient_workmail === client.recipient_workmail);
        let newSelected: IClientShortDetails[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, client);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    /**
     * checks whether email is in selected list and returns result
     * @param recipient_workmail 
     * @returns {boolean} found or not result
     */
    const isSelected = (recipient_workmail: string) => selected.findIndex(n => n.recipient_workmail === recipient_workmail) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, clientsList.length - page * rowsPerPage);

    /**
     * handles change of the page
     * @param event 
     * @param newPage 
     */
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    /**
     * handles change of numbers on the page
     * @param event 
     */
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // render circular progress in table
    const renderCircularProgress = () => (
        <TableRow>
            <TableCell size="medium" />
            <TableCell size="medium" />
            <TableCell size="medium" />
            <TableCell size="medium" />
            <TableCell size="medium" />
            <CircularProgress
                style={{ padding: '0px', margin: '20px auto' }}
            />
            <TableCell size="medium" />
            <TableCell size="medium" />
            <TableCell size="medium" />
        </TableRow>
    )

    // render table rows
    const renderRows = (): JSX.Element[] => {
        let rowIndex: number = 1
        const listOfRows = stableSort(clientsList, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((client, index) => {
                const isItemSelected: boolean = isSelected(client.recipient_workmail);
                const labelId: string = `enhanced-table-checkbox-${index}`;
                return (
                    <Row
                        key={index}
                        id={client.id}
                        row={client}
                        rowIndex={rowIndex++}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        handleSelectClick={handleSelectClick}
                    />
                );
            })
        return listOfRows
    }

    return (
        <Paper className={classes.root}>
            <SearchBar />
            <div className={classes.tableContainer}>
                <SelectedClientsToolbar numSelected={selected.length} selectedList={selected} />
                <TableContainer>
                    <Table className={classes.table} aria-label="collapsible table">
                        <ClientsTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={clientsList.length}
                        />
                        <TableBody style={{ fontFamily: 'Lato, sans-serif' }}>
                            {clients.loading ? renderCircularProgress() : renderRows()}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: ROW_HEIGHT * emptyRows }}>
                                    <TableCell colSpan={9} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className={classes.paginationActions}
                    style={{ backgroundColor: McK_DEEP_BLUE, color: 'white' }}
                    rowsPerPageOptions={ROWS_OPTIONS}
                    component="div"
                    count={clientsList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage} // Fix: Replace onChangePage with onPageChange
                    onRowsPerPageChange={handleChangeRowsPerPage} // Fix: Replace onChangeRowsPerPage with onRowsPerPageChange
                />
                <ActionAlert
                    isError={true}
                    isOpen={alert.open}
                    message={alert.message}
                    onClose={handleAlertClose}
                />
            </div>
        </Paper>
    )
}

export default ClientsTable