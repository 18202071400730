import { SecureRoutes } from "./pages"
import { Navbar } from './components';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { OKTA_AUTH_CONFIG } from "./config";
import { useNavigate } from 'react-router-dom';
import {LogoutButton} from "./components";
const oktaAuth = new OktaAuth(OKTA_AUTH_CONFIG);

function App() {

  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
  };
  const onAuthRequired = () => { oktaAuth.signInWithRedirect() };


  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={onAuthRequired}>
      <Navbar />
      <LogoutButton/>
    <SecureRoutes />

    </Security>
  )
}

export default App;
