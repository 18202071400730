import { useEffect } from "react"
import { makeStyles } from "@material-ui/core"
import { FunctionComponent } from "react"
import { ClientsTable } from "../components"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { fetchListOfClients } from "../redux/reducers/clients"
import { useOktaAuth } from '@okta/okta-react';
import { loginSuccess } from "../redux/reducers/admin"
import { trackStackTrace } from "../utils/logger"
import { appInsights } from "../config"
import { McKSansMedium } from "../styles/fonts"
import LogoutButton from '../components/LogoutButton';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        padding: '20px 20px',
        alignItems: 'center',
        fontFamily: 'Roboto, sans-serif',
    },
    heading: {
        textAlign: 'center',
        fontFamily: McKSansMedium,
        fontSize: '40px',
        marginTop: '30px',
    }
})




const Admin: FunctionComponent = () => {

    const dispatch = useAppDispatch()
    const { admin, clients } = useAppSelector(state => state)
    const { authState, oktaAuth } = useOktaAuth();
    const classes = useStyles()
    appInsights.trackPageView({ name: 'AdminPage' })
    useEffect(() => {

        // dispatch fetch of new hires if store is empty
        if (clients.list.length === 0) {
            oktaAuth.getUser()
                .then(info => {
                    trackStackTrace("fetch list of users by admin", { adminEmail: info?.email })
                })
            
            //accessToken is nullable so we need to check if it exists by adding Optional Chaining operator 
            dispatch(fetchListOfClients(authState?.accessToken?.accessToken))
        }

        // dispatch admin info only if its null
        if (authState?.isAuthenticated && admin.info === null) {
            oktaAuth.getUser()
                .then((info) => {
                    trackStackTrace("get admin okta information", { adminEmail: info?.email })
                    dispatch(loginSuccess(info))
                })
        }
    }, []);

    return (
        <div className={classes.root}>

            <LogoutButton />

            <h1 className={classes.heading}>Password magic link admin</h1>

            <ClientsTable />
           
        </div>
    )
}

export default Admin