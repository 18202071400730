import { UserClaims } from '@okta/okta-auth-js';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IAdmin {
    info: UserClaims | null
}

const initialState: IAdmin = {
    info: null,
}

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        loginSuccess: (state, action: PayloadAction<UserClaims>) => {
            state.info = action.payload;
        },
    },
})

const { reducer, actions } = adminSlice
// export actions
export const { loginSuccess } = actions
// export reducer
export default reducer