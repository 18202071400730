import {
    TableCell,
    TableSortLabel,
    TableHead,
    TableRow,
    Checkbox,
} from "@material-ui/core"
import { MouseEvent, ChangeEvent } from 'react'
import { IClientDetails } from '../../interfaces';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Order } from '../../utils/sort';

interface IProps {
    classes: ClassNameMap<"table" | "cell" | "visuallyHidden">
    numSelected: number;
    onRequestSort: (event: MouseEvent<unknown>, property: keyof IClientDetails) => void
    onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void
    order: Order
    orderBy: string
    rowCount: number
}

interface IHeadCell {
    id: string
    label: string
    align: string
}

// head cells with name and other properties
const headCells: IHeadCell[] = [
    { id: 'firstName', label: 'First name', align: 'left' },
    { id: 'lastName', label: 'Last name', align: 'left' },
    { id: 'recipient_workmail', label: 'Email', align: 'left' },
    { id: 'internal_cfs_upn', label: 'Email', align: 'left' },
    { id: 'accountStatus', label: 'Account status', align: 'center' },
    { id: 'actions', label: 'Actions', align: 'center' },
]

const ClientsTableHead = (props: IProps) => {
    const { classes, order, orderBy, numSelected, rowCount, onRequestSort, onSelectAllClick } = props;

    const createSortHandler = (property: keyof IClientDetails) => (event: MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                <TableCell />
                {headCells.map((headCell: IHeadCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align as any}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {
                            // don't show sorting label for account status and actions head cells
                            headCell.id !== "accountStatus" && headCell.id !== "actions"
                                ? (
                                    < TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id as keyof IClientDetails)}
                                    >
                                        {headCell.label}
                                        {
                                            orderBy === headCell.id ?
                                                (
                                                    <span className={classes.visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null
                                        }
                                    </TableSortLabel>
                                )
                                : headCell.label
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead >
    )
}

export default ClientsTableHead