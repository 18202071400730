import { OktaAuthOptions } from "@okta/okta-auth-js";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const BASE_URL: string = process.env.REACT_APP_OKTA_BASE_URL || 'https://dev-43008285.okta.com'
const CLIENT_ID: string = process.env.REACT_APP_CLIENT_ID || '0oafkbm2s5Y3faFRp5d7'

const ISSUER_ID: string = process.env.REACT_APP_OKTA_ISS_ID || 'default'
const AZURE_URL: string = process.env.REACT_APP_API_BASE_URL || 'https://nhpwdeliveryapimanamgent-dev.azure-api.net'
export const ADMIN_API_URL: string = `${process.env.NODE_ENV === "development" ? 'http://localhost:8080':`${AZURE_URL}/admin`}/v1`
export const ACCOUNT_STATUS_API_URL: string = `${process.env.NODE_ENV === "development" ? 'http://localhost:8081':`${AZURE_URL}/account-status`}/v1`

export const OKTA_AUTH_CONFIG: OktaAuthOptions = {
    issuer: `${BASE_URL}/oauth2/${ISSUER_ID}`,
    clientId: CLIENT_ID,
    redirectUri: `${window.location.origin}/login/callback`,
    postLogoutRedirectUri: `${window.location.origin}/`,
    scopes: ['openid', 'profile', 'email'],
    storageManager: {
        token: {
            storageType: 'localStorage'
        }
    
   }
}

export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'f6a31db4-7877-487e-af13-41eb52ec6d1f',
    }
})

appInsights.loadAppInsights()