import {
    List,
    Button,
    Dialog,
    ListItem,
    DialogTitle,
    ListItemText,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@material-ui/core";
import { Fragment, FunctionComponent } from "react";
import { FailedEmail } from "./BatchEmailActionButton";

interface IProps {
    open: boolean
    message: string
    failedList: FailedEmail[]
    handleClose: () => void
}

/**
 * Display batch email results in a Dialog
 * @param open open dialog true or false 
 * @returns 
 */
const BatchEmailDialog: FunctionComponent<IProps> = ({ open, message, failedList, handleClose }) => {
    return (
        <Fragment>
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-title"
            >
                <DialogTitle id="dialog-title">Email results</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {message.charAt(0).toUpperCase() + message.slice(1)}
                    </DialogContentText>
                    <List>
                        {
                            failedList.map((result: any) =>
                                <>
                                    <ListItem>
                                        <ListItemText style={{ color: 'red' }}>{result.email}</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>{result.error}</ListItemText>
                                    </ListItem>
                                </>
                            )
                        }
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default BatchEmailDialog